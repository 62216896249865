button {
	background-color: #0ec;
	border: none;
	border-radius: 4px;
	padding: 1em;
	margin: 0 .4em;
}

button:hover {
	cursor:pointer;
	background-color: #0fb;

}

.header {
	background-color: #dee;
	margin-top: 0;
	padding: .2em;
	margin-bottom: .2em;
}

.Controller {
	width: 80%;
	margin: auto;
}

.Libs {
	width: 50%;
	max-width: 800px;
	margin:auto;
	overflow: scroll;
	background: #dee;
	margin-top: 1em;
	border-radius: 4px;
	padding: .2em;
}

.Lib {
	text-align: left;
	margin: .4em 0;
}

.Lib-title {
	display: inline;
	padding-left: .5em;
}

.EditLib {
	border: none;
	border-radius: 4px;
	background-color: #dee;
	width: 100%;
}

.EditLib-title {
	border: none;
	border-radius: 4px;
	background-color: #dee;
	width: 100%;
}

.Edit {
	text-align: left;
}

.EditElement {
	margin: 1em 0;
}

.EditElement>label>input,.EditElement>label>textarea{
	padding: .2em;
	font-size: 1.2em;
}

.Instructions {
	font-style: italic;
	text-align: center;
	font-size: .8em;
}

.Play {
	background-color: #dee;
	font-size: 1.2em;
	padding: .1em .2em 1em .2em;
}
